




















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Services extends Vue {}
